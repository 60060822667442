<template>
   <div class="shopify-pagination-btn-group d-inline-flex">
                  <button :disabled="!hasPrevious" @click="$emit('onPrevious')" class="shopify-pagination-btn prev" title="Previous"><i class="fa fa-angle-left"></i></button>
                   <button :disabled="!hasNext"  @click="$emit('onNext')"  class="shopify-pagination-btn next" title="Next"><i class="fa fa-angle-right"></i></button>
                 </div>
</template>

<script>
export default {
props:{
    hasNext:{
        type:Boolean,
        default:true,
    },
     hasPrevious:{
        type:Boolean,
        default:true,
    }
}
}
</script>

<style scoped>

.shopify-pagination-btn:not(:disabled):active{
    background: #d7d7d7;
    box-shadow: 0rem 0.125rem 0.0625rem 0rem rgba(26,26,26,.2) inset, 0.0625rem 0rem 0.0625rem 0rem rgba(26,26,26,.12) inset, -0.0625rem 0rem 0.0625rem 0rem rgba(26,26,26,.12) inset!important;

}
.shopify-pagination-btn:disabled{
   cursor:not-allowed;
   fill: #4c545c;
    background: #d8d8d8;
}
.next{
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}
.prev{
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}
.shopify-pagination-btn
{
    fill: #73738D;
    position: relative;
    z-index: 10;
    display: inline-block;
    min-width: 2.4rem;
    margin: 0;
    padding: 0.4rem 0.4rem;
    background: #ebebeb;
    border: 1px solid transparent;
    color: #73738D;
    text-align: center;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    transition-property: color, background, border, box-shadow;
    transition-duration: 20ms;
    transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
}
</style>