<template>
  <div>
    <b-progress height="4px" :value="progress" :variant="variant" :max="100"></b-progress>
  </div>
</template>

<script>

export default {

  data() {
    return {
      progress: 0,
      interval: null,
    variant:'success'
    }
  },
  methods: {
    start() {
       this.variant='success';
      this.progress = 0;
      this.interval = setInterval(() => {
        if (this.progress >= 70) {
          clearInterval(this.interval);
        } else {
          this.progress += 1;
        }
      }, 100);
    },
    stop() {
       this.progress = 100;
          setTimeout(() => {
          clearInterval(this.interval);
          this.progress = 0;
          }, 2000);
    },
      error() {
        this.variant='danger';
        clearInterval(this.interval);
      
          setTimeout(() => {
          this.progress = 0;
          }, 500);
      
    },
    pause() {
      clearInterval(this.interval);
    },
  },
};
</script>
