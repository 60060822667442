<template>
  <div class="">
    <!-- v-if="getNavbarData.userType == 'shopify'" -->
    <!-- <button
      class="btn px-3 py-2 ml-2 shopify-btn"
      @click="openShopifyProductModal()">
      import from <i class="fab fa-wix"></i>
    </button> -->
    <!-- Shopify Modal -->
    <ModalVue
      ref="VariantModal"
      id="VariantModal"
      title="Add Products as variants"
      size="lg">
      <div class="mx-2">
        <div class="my-2" :class="!isLoadingSync ? 'd-none' : 'd-block'">
          <ProgressbarVue ref="progressbar"></ProgressbarVue>
          <span
            class="text-success text-center"
            style="font-size: 12px; text-align: center">
            Products syncing soon, continue tasks uninterrupted.
          </span>
        </div>
        <FilterBox
              class="w-100"
                :search="true"
                placeholder="Search Products..."
                 v-model="filter.name"></FilterBox>

           <div class="row mt-2"> 
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-wrap gap" >
              
              <FilterSelect type="radio" filterFor="Product Type" @update="(e)=>{filter.product_type=e.value}" :isDynamic="false" :staticOptions="[{title:'All',value:''},{title:'Physical',value:'physical'},{title:'Digital',value:'digital'}]"></FilterSelect>
              <FilterSelect type="radio" filterFor="Inventory Status" @update="(e)=>{filter.inventory_status=e.value}" :isDynamic="false" :staticOptions="[{title:'All',value:''},{title:'Stock',value:'IN_STOCK'},{title:'Out of Stock',value:'OUT_OF_STOCK'}]"></FilterSelect>
              <div class="d-flex">
                <FilterSelect type="radio" filterFor="Price" @update="(e)=>{filter.price.filter=e.value}" :isDynamic="false" :staticOptions="[{title:'All',value:''},{title:'Equal',value:'eq'},{title:'Less than or equal',value:'lte'},{title:'Greater than or equal',value:'gte'}]"></FilterSelect>
                 <b-form-input
                            type="number"
                            class="w-25"
                            v-if="filter.price.filter== 'eq'||filter.price.filter== 'lte'||filter.price.filter== 'gte'"
                            v-model="filter.price.value"
                            size="sm"
                            placeholder="Price"
                            autocomplete="false"
                            name="price-number"></b-form-input>
              </div>
            </div>
              
              <div
                class="d-flex align-items-center"
                v-if="isLoadingWixProducts">
                <b-spinner small style="color: #4d1b7e"></b-spinner>
                <span class="ml-2">Loading Products</span>
              </div>
            </div>
           
          </div>
          <div class="col-12 ">
            <div class="d-flex justify-content-between">
               <div class="d-flex align-items-center">
                
                        <b-form-checkbox
                          v-model="filter.hidden"
                          size="sm"
                          :disabled="
                            isLoadingWixProducts ||
                            isLoadingSelectedWixProducts ||
                            isLoadingSync
                          "
                          class="checkbox selectAll mx-2">
                          Show hidden products</b-form-checkbox
                        >
                        <!--  <b-form-checkbox
                          v-model="withVariant"
                          size="sm"
                          :disabled="
                            isLoadingWixProducts ||
                            isLoadingSelectedWixProducts ||
                            isLoadingSync
                          "
                          class="checkbox selectAll">
                          With Variant</b-form-checkbox
                        > -->
                      </div>
                        <div class="d-flex " style="transform:scale(0.8)">
                        <Button
                          @click="getProductsFromWixStore()"
                          class="px-2 py-1"
                          style="min-width: min-content">
                          Apply
                        </Button>
                        <button class="btn btn-link p-0 ml-2" @click=resetFilter>Reset</button>
                      </div></div>
            </div>
          <div class="col-12 col-lg-5 pl-lg-0">
           
          </div>
         
        </div>

        <b-skeleton-table
          v-if="isLoadingWixProductFirstTime"
          :rows="5"
          :columns="3"
          :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
        <div class="data-table" v-else-if="wixStoreProducts.length">
          <div
            class=""
            style="max-height: 300px; min-height: 300px; overflow: auto">
           
            <b-table
              head-class="text-center"
              class="leadTable"
              :stickyColumn="true"
              hover
              :fields="wixProductsFields"
              @row-clicked="rowClick"
              :items="wixStoreProducts">
              <template #head(checkbox)>
                <b-form-checkbox
                  size="lg"
                  :disabled="
                    isLoadingWixProducts ||
                    isLoadingSelectedWixProducts ||
                    isLoadingSync
                  "
                  v-model="wixSelectAllCheck"
                  @change="shopifyProductSelectAllToggle($event)"
                  class="checkbox selectAll"></b-form-checkbox>
              </template>
              <template #cell(checkbox)="data">
                <b-form-checkbox
                  :value="data.item"
                  v-model="wixSelectedProducts"
                  :disabled="
                    isLoadingWixProducts ||
                    isLoadingSelectedWixProducts ||
                    isLoadingSync
                  "
                  size="lg"
                  class="checkbox"></b-form-checkbox>
              </template>
              <template #cell(title)="data">
                <lazy-image
                  class="circle-image mr-2"
                  :src="
                    data.item.image
                      ? data.item.image
                      : 'https://images.quizell.com/default/profile_image.png'
                  "
                  :key="data.item.image"></lazy-image>
                {{ data.item.title  }} [<span class="mx-1" v-for="(choice,index) in data.item.variants[0].choices" :key="index">{{choice}}</span>]
              </template>
            </b-table>
          </div>
        </div>
        <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
<template #title>
No product found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
        <div class="d-flex justify-content-between">
          <ShopifyPagination
            :hasNext="
              !(
                isLoadingWixProducts ||
                isLoadingSelectedWixProducts ||
                isLoadingSync ||
                disableNext
              )
            "
            @onNext="NextPageCLick()"
            :hasPrevious="
              !(
                isLoadingWixProducts ||
                isLoadingSelectedWixProducts ||
                isLoadingSync ||
                page_offset == 0
              )
            "
            @onPrevious="PrevPageCLick()"></ShopifyPagination>
            <div class="">
              <Button
                class=""
                @click="syncSelected('')"
                :disabled="
                  isLoadingSync ||
                  isLoadingWixProducts ||
                  isLoadingSelectedWixProducts
                "
                :isLoading="isLoadingSelectedWixProducts"
                style="border-radius: 4px !important"
                v-if="wixSelectedProducts.length"
                >Sync Selected</Button
              >
              <BorderButton class="black-border-button" :disabled="
                  isLoadingSync ||
                  isLoadingWixProducts ||
                  isLoadingSelectedWixProducts
                "
                v-else
                @click="syncSelected('all')"
                :isLoading="isLoadingSelectedWixProducts">Sync All</BorderButton>
              
              
              </div>
        </div>
      </div>
    </ModalVue>
  </div>
</template>
<script>
import EmptyTable from "../../Layout/EmptyTable.vue";
  import { mapGetters } from "vuex";
  import { debounce } from "lodash";
  import ShopifyPagination from "../../Layout/ShopifyPagination.vue";
  import ModalVue from "../../Layout/Modal.vue";
  import ProgressbarVue from "../../Layout/Progressbar.vue";
  import Button from '../../Layout/Button.vue';
  import FilterSelect from '../../Layout/FilterSelect.vue';
  import FilterBox from '../../Layout/FilterBox.vue'
  import BorderButton from "../../Layout/BorderButton.vue";
  export default {
    components: {
      FilterBox,
      ShopifyPagination,
      ModalVue,
      Button,
      EmptyTable,
      ProgressbarVue,
      FilterSelect,
      BorderButton
    },
    data() {
      return {
        withVariant:false,
      page_offset: 0,
      page_limit: 50,
      disableNext:false,
        selectAllFromStore:false,
        isLoadingWixProducts: false,
        isLoadingWixProductFirstTime: false,
        wixSelectAllCheck: false,
        wixSelectedProducts: [],
        wixStoreProducts: [],
        wixProductsFields: [
          {
            key: "checkbox",
            label: "",
            tdClass: "align-middle data-Table cursor-pointer",
            thClass: "bg-table-heading",
          },
          {
            key: "title",
            label: "Product",
            tdClass: "align-middle data-Table cursor-pointer",
            thClass: "bg-table-heading",
          },
          {
            key: "price",
            label: "Price",
            tdClass: "align-middle data-Table cursor-pointer",
            thClass: "bg-table-heading",
          },
           {
            key: "product_type",
            label: "Product Type",
            tdClass: "align-middle data-Table cursor-pointer",
            thClass: "bg-table-heading",
          },
        ],
        items: [],
        isLoadingSelectedWixProducts: false,
        isLoadingDeleteProduct: false,
        isLoadingSync: false,
        isLoading: false,
        productProducts:[],
        filter:{
          name:'',
          hidden:false,
          price:{  
          value:"",
          filter:"" 
          },
    product_type:"",  
    inventory_status:""
        },
      };
    },
    watch: {
    filterObject(newValue,oldValue){
      if(newValue!=oldValue)
        this.page_offset=0;
    },
      "filter.name"(){
        this.handleSearchDebounced();
      },
    checkAllProductsAreSelected(){

    },
      allProducts:{
        handler({selected,all}){
         this.wixSelectAllCheck=all.every(product=>selected.includes(product));

        },
        deep:true
      },

    },
    computed: {
      zIndex(){
        return this.wixSelectedProducts.length>0?'1':'-1';
      },
  filterObject() {
    const obj = {...this.filter}

  if(obj.price){

    if (obj.price && obj.price.filter === "") {
      delete obj.price;
    }
  }

  for (const key in obj) {

      if (key=='filter'&& typeof obj[key] == 'object') {
        if(obj[key].filter=='')
        delete obj[key];
      } else if (obj[key] == "") {
        delete obj[key];
      }
  }
  return obj;
},
      ...mapGetters(["getNavbarData"]),
      allProducts(){
        return {selected:this.wixSelectedProducts,all:this.wixStoreProducts}
      },
      getSaveWixObject(){
        const object={
          filter:{},
          dynamic:false,
          allProduct:false,
          products:[],
        }
        if(this.selectAllFromStore)
        {
         object.filter={page_offset:this.page_offset, ...this.filterObject}
          object.allProduct=true
        }
        // if user select all products that are in table
      //  else if(this.wixAllProducts.length==this.wixSelectedProducts.length)
      //   {
      //     object.filter={page_offset:this.page_offset, ...this.filterObject}

          
      //   }
        // if user select random products from table
       else
        {
         object.dynamic=true;
        object.products=this.wixSelectedProducts
        }

        return object
      },
      wixAllProducts() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.wixStoreProducts
      },
    },
    methods: {
      rowClick(row){
        const index = this.wixSelectedProducts.findIndex(product=>product==row)
        if(index>-1)
        {
           this.wixSelectedProducts.splice(index,1)
           return
        }
         this.wixSelectedProducts.push(row)

      },
      resetFilter(){
        this.page_offset=0
        this.filter={
          name:'',
          hidden:false,
          price:{  
          value:"",
          filter:"" 
          },
          product_type:"",  
          inventory_status:""
        },
        this.getProductsFromWixStore();
      },
     NextPageCLick() {
      this.page_offset += 5;
      this.getProductsFromWixStore();
    },
     PrevPageCLick() {
      if (this.page_offset > 0) {
        this.page_offset -= 5;
        this.getProductsFromWixStore();
      }},
      open() {
        this.page_offset=0
        this.filter={
          name:'',
          hidden:false,
          price:{  
          value:"",
          filter:"" 
          },
          product_type:"",  
          inventory_status:""
        },
        this.isLoadingWixProductFirstTime = true;
        this.$refs.VariantModal.open();
        this.isLoadingSelectedWixProducts=false;
        this.wixSelectedProducts=[]
        this.getProductsFromWixStore();
      },
      async syncSelected(type='') {
        this.selectAllFromStore=false;
        if(type=='all')
        {
          this.selectAllFromStore=true;
        }
        this.isLoadingSelectedWixProducts = true;
        const response = await this.$store.dispatch('fetchData',{
          axiosMethod:'post',
          resource:'wixProducts',
          method:'saveWixVariantProducts',
          params:{
            with_variants:this.withVariant,
            ...this.getSaveWixObject}
        });

        if(response&&response.status)
        {
              this.$refs.VariantModal.close();
            // this.getProducts("/product/list");
            this.$emit('updateProducts')
            this.$toasted.show(response.message, {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
        }
        else {
 this.$toasted.show("Error occurred while syncing products", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
        }

        this.isLoadingSelectedWixProducts = false;
      },

      shopifyProductSelectAllToggle() {
        this.wixSelectedProducts = [];
        if (this.wixSelectAllCheck) {
          this.wixSelectedProducts = this.wixAllProducts;
        }
        else this.selectAllFromStore=false
      },
  
      async getProductsFromWixStore() {
        this.isLoadingWixProducts = true;
      const response=await this.$store.dispatch('fetchData',{
        axiosMethod:'post',
        resource:'wixProducts',
        method:'getWixVariantsProducts',
        params:{page_limit:this.page_limit,page_offset:this.page_offset,...this.filterObject}
       })
 
       if(response && response.status)
       {
   
          this.wixSelectedProducts=[];
          this.selectAllFromStore=false;
          this.wixStoreProducts=response.data.products
          if(this.wixStoreProducts.length < this.page_limit){
            this.disableNext = true
          }else{
            this.disableNext = false
          }
       }
        this.isLoadingWixProducts = false;
        this.isLoadingWixProductFirstTime = false;
      },
      handleSearchDebounced: debounce(function () {
        this.getProductsFromWixStore();
      }, 500), // 500ms debounce time, adjust as needed

    },
  };
</script>
<style scoped>
.shopify-btn:active {
  background-color: #002e25;
  color: #ffffff;
  border-color: #002e25;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #000;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
}
.loader {
  min-height: 40px;
}
.product-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#product-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
.search-text:focus-visible {
  outline: none;
}
.search-text:active + .fa-search {
  color: #4d1b7e;
}
.tag {
  background: #bfbfc0;
  border-radius: 20px;
  border: 1px dashed;
  font-size: 14px;
}
.icon {
  color: #949495;
  transition: 0.5s ease all;
}
.icon:hover {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.filter-active {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.search {
  border-radius: 6px;
  border: 0.8px solid #b3afb6;
}
.search:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.cursor-pointer {
  cursor: pointer;
}
#product-detail >>> button::after {
  display: none;
}
#product-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#product-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#product-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
/* .filter-controls::after{
    content:'';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #edebeb;
  } */
.sync-filtered:hover {
  color: #4d1b7e !important;
}
.product-stripe {
  position: relative;
  top: 38px;
  background: #f4f3f4;
  display: flex;
  align-items: center;
  top: 48px;
}
.product-stripe strong {
  color: #4d1b7e;
  cursor: pointer;
}
.product-stripe strong:hover {
  text-decoration: underline;
}
</style>
